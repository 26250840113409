import iconPaths from '../assets/icons.json';
import cineplexIconPaths from '../assets/icons-cineplex.json';

import type { ColorSchemeConfig } from './createColorScheme';

export type IconName = keyof typeof iconPaths;
type OS = 'ios' | 'web' | 'android' | 'default';
export type IconPaths = { [key in IconName]: string | { [key in OS]?: string } };
export type Brand =
	| 'CINURU'
	| 'CINFINITY'
	| 'CINEPLEX'
	| 'LUMOS'
	| 'TEST'
	| 'CINEWEB'
	| 'ELSELICHTSPIELE'
	| 'DREHWERK';

/** Generates the config for color scheme, typography, shadows, borderRadius, icons and images */
const createWhitelabelConfig = (
	brand: Brand,
	darkMode?: boolean
): {
	font?: 'ProximaNova';
	baseBorderRadius?: number;
	noShadow?: boolean;
	noBlur?: boolean;
	iconPaths: IconPaths;
	imageUrls?: { logo: string; headerLogo: string };
} & ColorSchemeConfig => {
	switch (brand) {
		case 'CINURU':
			return {
				tintStrength: 2,
				accentColor: darkMode ? '#0a7fff' : '#1492ff',
				accentGradient: darkMode ? ['#0dadf1', '#0f40b8'] : ['#00d9ff', '#376df0'],
				altAccentColors: darkMode ? ['#c226de', '#fa2f19'] : ['#cb3efa', '#ff4238'],
				altAccentGradients: darkMode
					? [
							['#f207a0', '#540082'],
							['#ff0083', '#ff0000'],
					  ]
					: [
							['#ff69bb', '#843dff'],
							['#ff4d9d', '#ff4c05'],
					  ],
				errorColor: '#ff4238',
				errorGradient: darkMode ? ['#ff0083', '#ff0000'] : ['#ff4d9d', '#ff4c05'],
				imageUrls: {
					logo: 'https://static.cinuru.com/public/logo_name_hor_medium.png',
					headerLogo: 'https://static.cinuru.com/public/logo_name_hor_small.png',
				},
				iconPaths,
			};
		case 'CINEPLEX':
			return {
				noShadow: true,
				noBlur: !darkMode,
				font: 'ProximaNova',
				baseBorderRadius: 0,
				tintStrength: darkMode ? 6 : 2,
				accentColor: '#0082d2',
				altAccentColors: ['#fed61e', '#dc1900', '#164A6F'],
				altAccentGradients: [['#ffc305', '#feeb3c']],
				neutralColors: ['#14171f', '#3d4a52', '#647780', '#97a6a8', '#d7dbdb', '#ffffff'],
				errorColor: '#dc1900',
				successColor: '#4FBB6D',
				successGradient: ['#42CC73', '#42AD60'],
				imageUrls: {
					logo: '',
					headerLogo: 'https://cdn-app.cineplex.de/app/cineplex/logo_header.png',
				},
				iconPaths: { ...iconPaths, ...cineplexIconPaths },
			};
		case 'CINFINITY':
			return {
				accentColor: '#f8563c',
				altAccentColors: ['#4114C8', '#000033'],
				backgroundColor: '#000033',
				tintStrength: 0,
				otherColors: ['#F7F0EA'],
				imageUrls: {
					logo: 'https://static.cinuru.com/public/cinema-logos/indiekino_header@3x.png',
					headerLogo: 'https://static.cinuru.com/public/cinema-logos/indiekino_header@1x.png',
				},
				iconPaths,
			};
		case 'TEST':
			// return {
			// 	accentColor: darkMode ? 'white' : 'black',
			// 	tintStrength: 0,
			// 	baseBorderRadius: 0.5,
			// 	imageUrls: {
			// 		logo: 'https://static.cinuru.com/public/cinema-logos/testcinema.png',
			// 		headerLogo: '',
			// 	},
			// 	iconPaths,
			// };
			// return {
			// 	accentColor: 'gold',
			// 	gradientStrength: 4,
			// 	tintStrength: 6,
			// 	baseBorderRadius: 0.25,
			// 	imageUrls: {
			// 		logo: 'https://static.cinuru.com/public/cinema-logos/testcinema.png',
			// 		headerLogo: '',
			// 	},
			// 	iconPaths,
			// };
			return {
				accentColor: '#1d979f',
				tintStrength: darkMode ? 4 : 1,
				baseBorderRadius: 0.5,
				gradientStrength: 7,
				imageUrls: {
					logo: 'https://static.cinuru.com/public/cinema-logos/cinecitta.png',
					headerLogo: 'https://static.cinuru.com/public/cinema-logos/cinecitta.png',
				},
				iconPaths,
			};
		case 'LUMOS':
			return {
				accentColor: '#f1cd82',
				backgroundColor: '#5e0303',
				noShadow: true,
				baseBorderRadius: 0.0625,
				imageUrls: {
					logo: 'https://static.cinuru.com/public/cinema-logos/lumos.png',
					headerLogo: 'https://static.cinuru.com/public/cinema-logos/lumos_header.png',
				},
				iconPaths,
			};
		case 'CINEWEB':
			return {
				accentColor: '#F7CD48',
				imageUrls: {
					logo: 'https://static.cinuru.com/public/cinema-logos/cineweb_logo@3x.png',
					headerLogo: darkMode
						? 'https://static.cinuru.com/public/cinema-logos/cineweb_header_logo_white@1x.png'
						: 'https://static.cinuru.com/public/cinema-logos/cineweb_header_logo_black@1x.png',
				},
				iconPaths,
			};
		case 'ELSELICHTSPIELE':
			return {
				tintStrength: 10,
				tintColor: '#E3BB84',
				accentColor: '#760807',
				accentColorDarkModeOverwrite: '#F24423',
				imageUrls: {
					logo: darkMode
						? 'https://static.cinuru.com/public/else-lichtspiele-logo-darkmode@3x.png'
						: 'https://static.cinuru.com/public/else-lichtspiele-logo-original@3x.png',
					headerLogo: darkMode
						? 'https://static.cinuru.com/public/else-lichtspiele-logo-darkmode.png'
						: 'https://static.cinuru.com/public/else-lichtspiele-logo-original.png',
				},
				iconPaths,
			};
		case 'DREHWERK':
			return {
				accentColor: '#ffffff',
				backgroundColor: '#930000',
				imageUrls: {
					logo: 'https://static.cinuru.com/public/drehwerk-logo.png',
					headerLogo: 'https://static.cinuru.com/public/drehwerk-logo@3x.png',
				},
				iconPaths,
			};
		default:
			throw new Error(`unknown brand '${brand}'`);
	}
};

export default createWhitelabelConfig;
